export const RouteConstants = {
  BASE: "/",
  LOGIN_CALLBACK: "/login/callback",
  GAMES: "/games",
  LINEUP_CARD: "/lineupCard",
  LOGO: "/logo",
  LOGIN: "/login",
  ADMIN: "/admin",
  CUSTOM_CARDS: "/customCards",
  EDIT_CUSTOM_CARD: "/customCards/edit",
  ROSTER_SETTINGS: "/rosterSettings",
  DEFAULT_LINEUPS: "/defaultLineups"
};
